.custom-accordian.ui.accordion .title:not(.ui) {
    font-size: 18px;
    font-weight: 700;



}
.custom-accordian{
    padding: 10px 20px;
    background: #F8F8F9;
    border-radius: 10px;
    margin-bottom: 20px;
}
.button-wrapper{
    display: flex;
    align-items: flex-end;
}
.button-wrapper button{
    margin-top: auto;
    height: 38px;
    margin-left: 10px !important;
}
.custom-label{
    font-size: .92857143em;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 10px;
}