.ai-blocked-users-layout .overview-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 36px 0 24px 0;
}
.ai-blocked-users-layout .mt-2 {
  margin-top: 7px !important;
}
.ai-blocked-card-outer {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}
.ai-blocked-card {
  background-color: #F8F8F9;
  border-radius: 8px;
  width: 33.333%;
  padding: 16px;
}
.ai-blocked-card .card-subtitle {
  color: #4B5563;
  display: block;
}
.ai-blocked-card .pending-review, .ai-blocked-card .block-review {
  margin-top: 10px;
}
.ai-blocked-card .pending-review {
  color: #BD880C;
}
.ai-blocked-card .block-review {
  color: #000000;
}
.ai-block-table-wrapper {
  margin-top: 32px;
  border: 1px solid #E8E9E9;
  border-radius: 8px;
  padding: 24px 24px 10px 24px;
}
.ai-block-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.ai-block-input-wrapper .search-input, .ai-block-input-wrapper .search-input  .ui.input {
  width: 100%;
}
.form-seach-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}
.search-input  .ui.input input {
  padding-inline-start: 40px;
}
.ai-block-input-wrapper .search-input  .ui.input>input::placeholder {
  color: #000000;
  opacity: 0.4;
}
.ai-blocked-users-layout .ui.collapsing.table.ai-block-table {
  width: 100%;
}
.ai-blocked-users-layout .ui.table tr th:first-of-type,
.ai-blocked-users-layout .ui.table tr td:first-of-type,
.ai-blocked-users-layout .ui.table tr th:nth-of-type(3),
.ai-blocked-users-layout .ui.table tr td:nth-of-type(3) {
  width: 20%;
}
.ai-blocked-users-layout .ui.table tr th:last-of-type,
.ai-blocked-users-layout .ui.table tr td:last-of-type {
  width: 27.2%;
}
.ai-maid-badge, .ai-client-badge, .ai-admin-badge {
  border-radius: 50px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 2px 8px !important;
  display: inline-block !important;
}
.ai-maid-badge {
  background-color: #C6EC69 !important;
  color: #453D59 !important;
}
.ai-client-badge {
  background-color: #453D59 !important;
  color: #FFFFFF !important;
}
.ai-admin-badge {
  background-color: #d9d9db !important;
  color: #2e2c2c !important;
}
.ai-blocked-users-layout .ui.header>.image+.content, .ai-blocked-users-layout .ui.header>img+.content {
  padding-inline-start: 12px;
}
.ai-blocked-users-layout .ai-block-table .ui.header>img  {
  height: 40px;
  width: 40px;
  margin: 0;
}
.ai-block-table .pending-status, .ai-block-table .block-status, .ai-block-table .unblock-status {
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50px;
  padding: 2px 8px;
}
.ai-block-table .pending-status {
  background-color: #FEF9C3;
  color: #854D0E;
}
.ai-block-table .block-status {
  background-color: #FEE2E2;
  color: #991B1B;
}
.ai-block-table .unblock-status {
  background-color: #e0e1e2;
  color: #4B5563;
}
.ai-block-table .btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.ai-blocked-users-layout .btn-group .button, .ui.modal.review-ai-modal>.actions .button {
  margin: 0;
  border-radius: 8px;
}

/* Review AI Modal */
.ui.modal.review-ai-modal {
  border-radius: 8px;
  padding: 24px;
}
.ui.modal.review-ai-modal .review-block-heading {
  padding: 0 0 24px 0;
}
.ui.review-ai-modal>.close {
  top: 30px;
  right: 24px;
  padding: 0;
  color: #6B7280;
  width: auto;
  height: auto;
  margin: 0;
  opacity: 1;
}
.ui.modal.review-ai-modal>.actions {
  border-top: 1px solid #E5E7EB;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 24px 0 0 0;
}
.ui.modal.review-ai-modal>.content {
  padding: 24px 0;
  /* overflow-y: auto;
  height: calc(100% - 109px); */
}
.review-ai-modal .info-wrapper {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 24px;
}
.info-wrapper .info-inner {
  width: calc(50% - 12px);
  background-color: #F9FAFB;
  border-radius: 8px;
  padding: 20px;
}
.info-wrapper .ui.header {
  color: #000000;
}
.review-history-wrapper {
  padding-top: 24px;
}
.review-history-table-wrapper {
  overflow: auto;
  position: relative;
  max-height: 385px;
}
.review-history-table-wrapper::-webkit-scrollbar {
  display: none;
}
.ui.table.review-history-table {
  width: 100%;
  margin: 0;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  color: #111827;
}
.review-history-table.ui[class*="very basic"].table:not(.sortable):not(.striped) td:first-child {
  padding: 16px 12px 16px 20px;
}
.review-history-table.ui[class*="very basic"].table:not(.sortable):not(.striped) td:last-child {
  padding: 16px 20px 16px 12px;
}
.review-history-table.ui.table tbody tr {
  vertical-align: top;
}
.review-history-table.ui.table tbody tr:last-of-type td:first-of-type {
  border-radius: 0 0 0 8px;
}
.review-history-table.ui.table tbody tr:last-of-type td:last-of-type {
  border-radius: 0 0 8px 0;
}
.review-history-table.ui.table td {
  padding: 16px 12px;
}
.review-history-table.ui.table td:first-of-type {
  width: 20%;
}
.review-history-table.ui.table td:nth-of-type(2) {
  width: 15%;
}
.review-history-table .mt-1 {
  margin-top: 4px !important;
}
.review-history-table .review-date {
  color: #6B7280;
}
.review-history-table.ui.basic.table .review-maid-row td {
  background-color: #F9FAFB;
}
.review-history-table.ui.basic.table .review-flagged-row td {
  background-color: #FEFCE8;
}
.review-flagged-row .flagged-list {
  margin-top: 10px;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
}
.review-flagged-row .flagged-item {
  position: relative;
  background-color: #FFF8AC;
  color: #854D0E;
  border-radius: 6px;
  padding: 4px 8px 4px 20px;
  list-style-type: none;
}
.review-flagged-row .flagged-item::before {
  content: '';
  position: absolute;
  left: 8px;
  top: 10px;
  height: 6px;
  width: 6px;
  background-color: #854D0E;
  border-radius: 50px;
}
.block-section:not(:last-of-type) {
  margin-bottom: 16px;
}
.block-section .review-block-title {
  margin-bottom: 8px;
}
.block-section .warning-box {
  background-color: #FEFCE8;
  border: 1px solid #00000017;
  padding: 16px 12px;
  border-radius: 6px;
}
.block-section .warning-title {
  font-weight: 600;
  margin-bottom: 4px;
  color: #000000;
}
.block-section .warning-description {
  color: #4B5563;
}
.review-ai-modal .info-box {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 12px 16px;
  border-radius: 6px;
}
.block-section .info-primary, .block-section .info-text {
  font-weight: 500;
  color: #000000;
}
.block-section .info-secondary {
  color: #4B5563;
  margin-top: 4px;
}
.info-inner .profile-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.info-inner .profile-block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.review-ai-modal .profile-block .image {
  width: 64px;
  height: 64px;
}
.profile-block .profile-name {
  margin-bottom: 4px;
}
.review-ai-modal .ui.button {
  border-radius: 8px;
  margin: 0;
}
.user-info-list {
  margin-top: 16px;
}
.user-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}
.user-info-item:not(:last-of-type) {
  border-bottom: 1px solid #E5E7EB;
}
.review-block-title {
  color: #4B5563;
}
.user-info-item .user-value {
  font-weight: 500;
  color: #000000;
}
.user-info-item .user-value.enable-value {
  color: #16A34A;
}

.paging-btn-style {
  padding: 15px 0px !important;
}

@media only screen and (min-width: 992px) {
  .ui.modal.review-ai-modal {
    width: 958px;
  }
}
