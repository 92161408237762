/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */


.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled,
.ui.form .field.disabled>label,
.ui.form .fields.disabled>label,
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}
.ui.form .field.disabled :disabled {
  background: #DEDEDF;
  border-color: #DEDEDF;
  color: rgba(0, 0, 0, 0.5);
}

.form-group-error {
  display: inline-block;
  width: 100%;
  position: relative;
  top: -1em;
  font-size: 0.85rem;
  color: #ff0000;
}

.text-capitalize {
  text-transform:capitalize
}
/* ======== */
/* Spacing */
/* ======= */
.pl-0{
  padding-left: 0 !important;
}
.p-0{
  padding: 0 !important;
}
.p-30 {
  padding: 30px !important;
}
.pl-30 {
  padding-left: 15px !important;
}
.p-30-15 {
  padding: 30px 15px !important;
}


.gray-box{
  padding: 30px;
  border-radius: 8px;
  background-color: #F8F8F9;
}

.pointer-none{
  pointer-events: none;
}

.text-danger {
  color: #db2828;
}